import React from "react";
import styled, { CSSProperties } from "styled-components";
import Heading, { HeadingProps } from "./Heading";

interface ContentBlock extends HeadingProps {
  content?: string;
  style?: CSSProperties;
}
const ContentBlock: React.FC<ContentBlock> = ({
  blockColor,
  color,
  text,
  content,
  style,
}) => (
  <ContentContainer style={style}>
    <Heading blockColor={blockColor} color={color} text={text} />
    {content && <Info>{content}</Info>}
  </ContentContainer>
);

export default ContentBlock;

const ContentContainer = styled.div`
  display: flex;
  max-width: 1440px;
  align-self: center;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.tablet}px) {
    /* background: linear-gradient(170deg, orange 80%, white 30%); */
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const Info = styled.p`
  font-size: 20px;
  max-width: 800px;
  margin-top: 0px;

  @media (max-width: ${({ theme }) => theme.mobile}px) {
    font-size: 16px;
  }
`;
