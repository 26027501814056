import React from "react";
import styled from "styled-components";
import { MdPhone } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
import { FaHome } from "react-icons/fa";

const ContactInfo: React.FC = () => (
  <>
    <Wrapper>
      <Home color="orange" size={60} />
      {`Lange Reksestraat 17`} <br /> 4538 AZ Terneuzen
    </Wrapper>
    <Wrapper>
      <Phone color="orange" size={60} />
      <a href="tel:0115683270">0115 683 270</a>
    </Wrapper>
    <Wrapper>
      <Email color="orange" size={60} />
      <a href={"mailto:info@topvormprefab.nl"}>info@topvormprefab.nl</a>
    </Wrapper>
  </>
);
export default ContactInfo;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 18px;

  @media (max-width: ${({ theme }) => theme.mobile}px) {
    padding: 18px 0px;
  }
`;

const Phone = styled(MdPhone)``;
const Email = styled(IoIosMail)``;
const Home = styled(FaHome)``;
