import React from "react";
import styled from "styled-components";
import ContentBlock from "../common/components/ContentBlock";

const About: React.FC = () => (
  <Container>
    <ContentBlock
      text="Over ons"
      blockColor="orange"
      content="In Terneuzen maken wij onder Komo-certificaat onze prefab daken zodat
        kwaliteit is gewaarborgd. Dit ook in het kader van de Wet
        Kwaliteitsborging in de bouw. (WKB) Duizenden woningen hebben wij de
        afgelopen jaren mogen voorzien van een prefab dak, dakkapel en/of HSB
        wand. Grote vrijstaande villa’s maar ook seriematige projecten van meer
        dan 100 woningen. Appartementsgebouwen behoren eveneens tot de
        mogelijkheden. Indien u gebruik maakt van prefab daken, dakkapellen en
        HSB wanden dan bent u bij ons aan het juiste adres. Door gebruik van
        goede 3D software sluiten gegarandeerd de elementen naadloos op elkaar
        aan. Hierdoor worden huizen met onze daken niet alleen duurzaam maar ook
        energiezuinig. Nu de BENG eraan komt zullen deze thema’s nog
        belangrijker worden."
    />
  </Container>
);
export default About;

const Container = styled.div`
  /* background: linear-gradient(140deg, orange 80%, white 30%); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 64px;
`;
