import React from "react";
import { StyledBurger } from "./Burger.styled";

const Burger: React.FC<{ open: boolean; setOpen: (open: boolean) => void }> = ({
  open,
  setOpen
}) => (
  <StyledBurger open={open} onClick={() => setOpen(!open)}>
    <div />
    <div />
    <div />
  </StyledBurger>
);

export default Burger;
