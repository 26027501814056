import React, { useRef } from "react";
import styled from "styled-components";
import Green from "./green";
import Header from "../common/components/Header";
import About from "./About";

const Home: React.FC = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Container ref={containerRef}>
        <Header />

        <Content ref={contentRef}>
          <About />
          <Green />
        </Content>
      </Container>
    </>
  );
};

export default Home;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
