import React, { useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "./common/theme";
import { GlobalStyles } from "./common/global";
import Menu from "./common/menu/Menu";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./home";
import ProjectsOverview from "./projects";
import smoothscroll from "smoothscroll-polyfill";
import Contact from "./home/Contact";
import Vacancies from "./vacancies";
smoothscroll.polyfill();

const App: React.FC = () => {
  const [{ width }, setDimensions] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = width <= theme.tablet;

  return (
    <Router>
      <GlobalStyles />
      <Container>
        <ThemeProvider theme={theme}>
          <Menu isMobile={isMobile} />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/topvorm"></Route>
            <Route path="/projecten">
              <ProjectsOverview isMobile={isMobile} />
            </Route>
            <Route path="/vacatures">
              <Vacancies />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
          </Switch>
        </ThemeProvider>
      </Container>
    </Router>
  );
};

export default App;

const Container = styled.div`
  display: flex;
  flex: 1;
  max-width: 100vw;
  min-height: 100vh;
  flex-direction: column;
`;
