import React, { useEffect, useState, useRef } from "react";
import { StyledMenu, StyledMobileMenu } from "./Menu.styled";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { FaLinkedin } from "react-icons/fa";
import Burger from "./Burger";

const Menu: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const [navBackground, setNavBackground] = useState(false);
  const navRef = useRef<boolean>();
  navRef.current = navBackground;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 20;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const backgroundColor = navBackground ? "grey" : "transparent";
  const menuItemColor = navBackground ? "white" : "black";
  const closeMenu = () => setOpen(false);
  const [open, setOpen] = useState(false);

  if (!isMobile)
    return (
      <>
        <StyledMenu backgroundColor={backgroundColor} linkColor={menuItemColor}>
          <Logo src={require("../../media/Logo.png")} />
          <Links />
          <LinkedIn
            color={"#0072b1"}
            size={40}
            onClick={() =>
              window.open("https://nl.linkedin.com/company/topvormprefab")
            }
          />
        </StyledMenu>
      </>
    );
  return (
    <>
      <Burger setOpen={setOpen} open={open} />
      <StyledMobileMenu open={open}>
        <Links onLinkPressed={closeMenu} />
      </StyledMobileMenu>
    </>
  );
};

const Links: React.FC<{ onLinkPressed?: () => void }> = ({ onLinkPressed }) => (
  <>
    <NavLink exact activeStyle={activeStyle} to="/" onClick={onLinkPressed}>
      Home
    </NavLink>
    <NavLink activeStyle={activeStyle} to="/projecten" onClick={onLinkPressed}>
      Projecten
    </NavLink>
    <NavLink activeStyle={activeStyle} to="/vacatures" onClick={onLinkPressed}>
      Vacatures
    </NavLink>
    <NavLink activeStyle={activeStyle} to="/contact" onClick={onLinkPressed}>
      Contact
    </NavLink>
  </>
);

export default Menu;

const activeStyle = {
  borderBottom: "2px solid orange",
  padding: 6,
};

const Logo = styled.img`
  position: absolute;
  object-fit: scale-down;
  height: 50px;
  left: 64px;

  @media (max-width: ${({ theme }) => theme.tablet}px) {
    height: 60px;
    top: 1em;
    left: 2em;
    position: fixed;
    z-index: 2;
  }
`;

const LinkedIn = styled(FaLinkedin)`
  position: absolute;
  right: 64px;
  cursor: pointer;
`;
