import * as React from "react";
import styled from "styled-components";

export interface HeadingProps {
  text: string;
  color?: string;
  blockColor?: string;
}

const Heading: React.FC<HeadingProps> = ({
  color = "black",
  blockColor,
  text,
}) => {
  return (
    <Container>
      <Block style={{ backgroundColor: blockColor ?? color }} />
      <Text style={{ color: color }}>{text}</Text>
    </Container>
  );
};
export default Heading;

const Container = styled.div`
  flex-direction: column;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

const Block = styled.div`
  display: flex;
  width: 8px;
  align-self: stretch;
`;

const Text = styled.h5`
  font-size: 46px;
  font-weight: bold;
  align-self: center;
  margin: 0px;
  padding-bottom: 4px;
  margin-left: 8px;

  @media (max-width: ${({ theme }) => theme.tablet}px) {
    /* background: linear-gradient(170deg, orange 80%, white 30%); */
    font-size: 24px;
  }
`;
