import React from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./style.css";

const Projects: React.FC<{ isTablet: boolean }> = ({ isTablet }) => {
  const images = [
    { original: require("../media/projecten4.jpg") },
    { original: require("../media/projecten5.jpg") },
    { original: require("../media/projecten6.jpg") },
    { original: require("../media/projecten7.jpg") },
    { original: require("../media/projecten8.jpg") },
    { original: require("../media/projecten9.jpg") },
    { original: require("../media/projecten10.jpg") },
    { original: require("../media/projecten11.jpg") },
    { original: require("../media/projecten12.jpg") },
    { original: require("../media/projecten13.jpg") },
    { original: require("../media/projecten14.jpg") },
    { original: require("../media/projecten15.jpg") },
    { original: require("../media/projecten16.jpg") },
    { original: require("../media/projecten17.jpg") },
    { original: require("../media/projecten18.jpg") },
    { original: require("../media/projecten19.jpg") },
  ];

  return (
    <Container>
      <Carousel
        swipeable={true}
        width={isTablet ? undefined : 800}
        showIndicators={false}
      >
        {images.map((img) => (
          <Image src={img.original} />
        ))}
      </Carousel>
    </Container>
  );
};

export default Projects;

const Image = styled.img`
  height: 600px;
  object-fit: contain;

  @media (max-width: ${({ theme }) => theme.tablet}px) {
    max-height: 400px;
  }
`;
const Container = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
  background-color: black;
`;
