import React from "react";
import styled from "styled-components";
import ContentBlock from "../common/components/ContentBlock";
const topics = [
  {
    img: require("../media/komo.jpg"),
  },
  {
    img: require("../media/fsc.jpeg"),
  },
  {
    img: require("../media/stip.jpg"),
  },
];
const Green: React.FC = () => {
  return (
    <Container>
      <ContentBlock
        text="Verantwoord ondernemen"
        blockColor="#32cd32"
        content="Op het gebied van duurzaam, energiezuinig en luchtdicht bouwen hebben
        wij de nodige stappen gemaakt. Dit is een continu proces, dat nooit af
        is. Daken met Rc = 6.3 maar ook daken met Rc = 10.0 worden bij ons
        speciaal samengesteld. Voor de hogere Rc-waarden is geen gewoon
        sporenhout verkrijgbaar en hiervoor worden special I-liggers gebruikt.
        Onze producten bevatten een Stip® en/of FSC® certificaat. Let op het FSC® logo voor onze FSC®-gecertificeerde producten"
      />

      <TopicsContainer>
        {topics.map((topic, i) => (
          <Topic key={`key${i}`} {...topic} />
        ))}
      </TopicsContainer>
    </Container>
  );
};

const Topic: React.FC<{ img: string }> = ({ img }) => {
  return (
    <TopicContainer>
      <TopicImage src={img} />
    </TopicContainer>
  );
};

export default Green;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  margin-bottom: 80px;
`;

const TopicsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 630px;
  margin-top: 50px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.mobile}px) {
    min-width: 0px;
    justify-content: center;
  }
`;

const TopicContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  margin: 0px 20px;
  max-width: 70%;
  align-items: center;
  transition: 1s ease;
  border-bottom: 10px solid white;

  &:hover {
    border-bottom: 10px solid #32cd32;
  }
`;

const TopicImage = styled.img`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 180px;
  height: 270px;
  padding: 20px;
  border-bottom: 2px solid transparent;
  border-radius: 8px;
  object-fit: contain;

  @media (max-width: ${({ theme }) => theme.tablet}px) {
    min-width: 0px;
    width: 140px;
    height: 210px;
    justify-content: center;
    border-bottom: 10px solid #32cd32;
  }
`;
