import axios from "axios";
import * as React from "react";
import styled from "styled-components";
import VacancyCard, { Vacancy } from "./components/Vacancy";

const Vacancies: React.FC = () => {
  const [vacancies, setVacancies] = React.useState<Vacancy[]>([]);

  const loadVacancies = async () => {
    const { data: res } = await axios.get(
      "https://topvorm.realize-it.nl/v1/vacancies"
    );
    setVacancies(res.data);
  };

  React.useEffect(() => {
    loadVacancies();
  }, []);

  return (
    <Container>
      {vacancies.map((v) => (
        <VacancyCard {...v} />
      ))}
    </Container>
  );
};

export default Vacancies;

const Container = styled.div`
  justify-content: center;

  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 128px;
`;
