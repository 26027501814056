import * as React from "react";
import styled from "styled-components";
import { FaMobile, FaEnvelope, FaPhone } from "react-icons/fa";
import ReactHtmlParser from "react-html-parser";
import "../style/index.css";

export interface Vacancy {
  title?: string;
  requirements?: string[];
  benefits?: string[];
  description: string;
  contactPerson: string;
  phone?: string;
  email?: string;
  mobile?: string;
}

const VacancyCard: React.FC<Vacancy> = ({
  title,
  description,
  phone,
  mobile,
  email,
}) => {
  return (
    <Card>
      <Title>{title}</Title>
      {ReactHtmlParser(description)}
      {email && (
        <PhoneWrapper href={`mailto:${email}`}>
          <Email size={28} /> {email}
        </PhoneWrapper>
      )}
      {mobile && (
        <PhoneWrapper href={`tel:${mobile.replace(/\s/g, "")}`}>
          <Mobile size={28} width={28} height={24} /> {mobile}
        </PhoneWrapper>
      )}
      {phone && (
        <PhoneWrapper href={`tel:${phone.replace(/\s/g, "")}`}>
          <Phone size={28} /> {phone}
        </PhoneWrapper>
      )}
    </Card>
  );
};
export default VacancyCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  border: 2px solid #f2f2f2;
  border-radius: 4px;
  padding: 24px 24px;
  align-self: center;
  margin-bottom: 64px;
  @media (max-width: ${({ theme }) => theme.mobile}px) {
    font-size: 16px;
    max-width: 360px;
  }
  overflow: hidden;

  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const Title = styled.h2`
  margin-top: 0px;
  margin-bottom: 0px;
  @media (max-width: ${({ theme }) => theme.mobile}px) {
    font-size: 20;
  }
`;

const PhoneWrapper = styled.a`
  display: flex;
  align-items: center;
  vertical-align: center;
  @media (max-width: ${({ theme }) => theme.mobile}px) {
    padding: 4px 0px;
  }
  cursor: pointer;
`;

const Mobile = styled(FaMobile)`
  color: black;
  padding-right: 8px;
  &:hover {
    opacity: 0.4;
  }
`;

const Phone = styled(FaPhone)`
  color: black;
  padding-right: 8px;
  &:hover {
    opacity: 0.4;
  }
`;

const Email = styled(FaEnvelope)`
  color: black;
  padding-right: 8px;
  margin-top: 4px;
  &:hover {
    opacity: 0.4;
  }
`;
