import * as React from "react";
import styled from "styled-components";
import ReactMapboxGl, { Marker, Popup } from "react-mapbox-gl";

import { FaHome } from "react-icons/fa";
import ContactInfo from "../contact/components/ContactInfo";
import { useState } from "react";

interface contactProps {}

const Map = ReactMapboxGl({ accessToken: process.env.REACT_APP_MAPBOX_KEY });
const coordinates = [3.850215, 51.3033983];

const Contact: React.FC<contactProps> = () => {
  const [showDetails, setShowDetails] = useState(true);
  const toggleDetails = () => setShowDetails(!showDetails);

  return (
    <Container>
      <Map
        /* eslint react/forbid-prop-types: 0 */
        style={"mapbox://styles/mapbox/streets-v9" as Object}
        containerStyle={{
          height: "100vh",
          width: "100%",
        }}
        center={[3.850215, 51.3033983]}
      >
        <Marker coordinates={coordinates}>
          <Home size={24} onClick={toggleDetails} />
        </Marker>
        {showDetails && (
          <Popup
            style={{ zIndex: 1 }}
            coordinates={coordinates}
            offset={[0, -20]}
          >
            <ContactInfo />
          </Popup>
        )}
      </Map>
    </Container>
  );
};
export default Contact;
const Container = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const Home = styled(FaHome)`
  color: orange;
  cursor: pointer;
`;
