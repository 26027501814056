import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import {
  IoIosArrowDown,
  IoMdPlay,
  IoMdPause,
  IoMdVolumeMute,
  IoMdVolumeHigh,
} from "react-icons/io";
import objectFitVideos from "object-fit-videos";

const Header: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [muted, setMuted] = useState(true);
  const [isPLaying, setIsPlaying] = useState(true);
  const video = useRef<HTMLVideoElement>();

  useEffect(() => {
    objectFitVideos();
  }, []);

  const scrollToRef = () => {
    if (containerRef && containerRef.current)
      window.scrollBy({
        //minus the header
        top: containerRef.current.clientHeight,
        behavior: "smooth",
      });
  };

  const toggleMuted = () => {
    setMuted(!muted);
  };

  const start = async () => {
    setIsPlaying(true);
    await video.current?.play();
  };

  const pause = async () => {
    setIsPlaying(false);
    await video.current?.pause();
  };

  return (
    <Container ref={containerRef}>
      <ContentOverlay>
        <TextContainer>
          <Title>Details tot in de puntjes.</Title>

          <HeaderText>
            Topvorm Prefab is gespecialiseerd in het ontwerpen, tekenen,
            produceren en just-in-time leveren van prefab daken en dakkapellen.
            Ook HSB-gevels behoren tot het assortiment.
          </HeaderText>
        </TextContainer>
        <GoDownIcon onClick={scrollToRef} size={80} />
        {isPLaying ? (
          <Pause size={36} onClick={pause} />
        ) : (
          <Start size={36} onClick={start} />
        )}
        {muted ? (
          <UnMute size={36} onClick={toggleMuted} />
        ) : (
          <Mute size={36} onClick={toggleMuted} />
        )}
      </ContentOverlay>
      <BackgroundVideo
        loop={true}
        autoPlay={true}
        playsInline={true}
        muted={muted}
        ref={video}
      >
        <source
          src={require("../../media/header_video.mp4")}
          type="video/mp4"
        />
      </BackgroundVideo>
    </Container>
  );
};

export default Header;

const Start = styled(IoMdPlay)`
  position: absolute;
  bottom: 24px;
  left: 24px;
  z-index: 1;
  cursor: pointer;
`;

const Pause = styled(IoMdPause)`
  position: absolute;
  bottom: 24px;
  left: 24px;
  z-index: 1;
  cursor: pointer;
`;

const Mute = styled(IoMdVolumeMute)`
  position: absolute;
  bottom: 24px;
  left: 60px;
  z-index: 1;
  cursor: pointer;
`;

const UnMute = styled(IoMdVolumeHigh)`
  position: absolute;
  bottom: 24px;
  left: 60px;
  z-index: 1;
  cursor: pointer;
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  margin-top: -70px;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.mobile}px) {
    margin-top: 0px;
  }
`;

const BackgroundVideo = styled.video`
  object-fit: cover;
  height: 100%;
  width: 100%;
  font-family: "object-fit: cover;";
`;

const GoDownIcon = styled(IoIosArrowDown)`
  color: orange;
  z-index: 1;
  bottom: 30px;
  cursor: pointer;
  &:hover {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
  }
`;

const HeaderText = styled.p`
  text-align: center;
  font-size: 24px;
  margin: 10px;
  font-weight: 400;
  color: white;
  text-shadow: 2px 2px #000000;
  @media (max-width: ${({ theme }) => theme.mobile}px) {
    font-size: 18px;
  }
`;
const Title = styled.h1`
  font-size: 2.5em;
  text-align: center;
  color: white;
  text-shadow: 2px 2px #000000;
  @media (max-width: ${({ theme }) => theme.mobile}px) {
    font-size: 1.5em;
  }
`;

const TextContainer = styled.div`
  margin: auto;
  max-width: 800px;
`;

const ContentOverlay = styled.div`
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
