import React from "react";
import styled from "styled-components";
import ContentBlock from "../common/components/ContentBlock";
import Projects from "./Projects";

const ProjectsOverview: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  return (
    <Container>
      <HeaderImage src={require("../media/projects-header.jpg")} />

      <ContentBlock
        text="Projecten"
        blockColor="orange"
        style={{ marginTop: isMobile ? 32 : 64 }}
        content="Wij hebben de afgelopen jaren honderden projecten verspreid over
      Nederland mogen realiseren samen met onze relaties. Projecten variërend
      van enkele woningen tot grote projecten met meer dan 100 woningen.
      Rijwoningen, twee-onder-een-kap woningen en vrijstaande woningen. We
      maken het allemaal. In onze fabriek in Terneuzen produceren wij het
      meest scharnierdaken. Deze gescharnierde prefab elementen hebben aan
      beide zijden een overspanning van nok naar goot en meestal een
      ondersteuning door middel van knieschotten. Maar ook lessenaars kappen
      enkelzijdige kapconstructies met een overspanning van nok naar goot
      worden er gemaakt. Gording kappen zijn prefab elementen met een boven
      beplating en een horizontale overspanning van bouwmuur naar bouwmuur.
      Deze kappen worden toegepast indien om technische redenen een
      constructieve overspanning van nok naar goot niet mogelijk is."
      />

      <Projects isTablet={isMobile} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-top: -70px;
`;
const HeaderImage = styled.img`
  width: 100%;
  max-height: 70vh;
  object-fit: cover;
  @media (max-width: ${({ theme }) => theme.mobile}px) {
    height: 400px;
  }
`;

export default ProjectsOverview;
