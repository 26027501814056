// Menu.styled.js
import styled from "styled-components";

export const StyledMobileMenu = styled.nav<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: grey;
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};

  a {
    font-size: 2rem;
    text-transform: uppercase;
    margin-right: 20px;

    letter-spacing: 0.5rem;
    color: black;
    text-decoration: none;
    transition: color 0.3s linear;
    margin-right: 0rem 10rem;

    transition: 0.3s ease;
    font-size: 20px;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    padding: 20px 0;
    margin: 0 20px;

    &:hover {
      border-bottom: 2px solid orange;
      padding: 6px 0;
    }
  }
`;

export const StyledMenu = styled.div<{
  backgroundColor: string;
  linkColor: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 70px;
  z-index: 100;
  position: sticky;
  opacity: 0.9;
  position: -webkit-sticky;
  top: 0;
  background: ${({ backgroundColor }) => backgroundColor};
  transition-property: background-color;
  transition-duration: 1s;

  a {
    font-size: 2rem;
    text-transform: uppercase;
    margin-right: 20px;

    letter-spacing: 0.5rem;
    color: ${({ linkColor }) => linkColor};
    text-decoration: none;
    transition: color 0.3s linear;
    margin-right: 0rem 10rem;

    transition: 0.3s ease;
    font-size: 20px;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    padding: 20px 0;
    margin: 0 20px;

    &:hover {
      border-bottom: 2px solid orange;
      padding: 6px 0;
    }
  }
`;
